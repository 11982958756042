import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/remix";

declare global {
  interface Window {
    ENV: {
      ENVIRONMENT?: string;
      NODE_ENV?: string;
      V2_API: string;    
      NEXUS_API_KEY: string;
      PAYITOFF_SCRIPT_URL: string;
      RECAPTCHA_SITEKEY: string;
      UPLOADS_CDN_URL_PREFIX: string;
      GA_MEASUREMENT_ID: string;
    };
  }
}

const env = window.ENV?.ENVIRONMENT || window.ENV?.NODE_ENV || 'DEVELOPMENT';

if (env.toUpperCase() === 'PRODUCTION') {
  Sentry.init({
    dsn: "https://ed08b77848d392317a765ef0587096a8@o4507579990605824.ingest.us.sentry.io/4507583676088320",
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // Replay is only available in the client
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env?.ENVIRONMENT || process.env.NODE_ENV,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https?:\/\/([a-zA-Z0-9-]+\.)*bestmoneymoves\.com(\/.*)?$/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
